$teal: #33CC99;
$shadow-gray: #2E2E2E;
$maroon: #91297e;
$blue: #000058;
$blue-hover: #04049f;
$red: #b53737;

$primary-font: 'Roboto', sans-serif;
$secondary-font: 'Roboto Slab', serif;

$box-shadow: 0px 1px 4px -1px $shadow-gray;


