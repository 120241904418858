.banner-img { 
    background-size: cover;
    height: 550px;
    width: 100%;
    margin-bottom: 80px;

    @include respond(phone-lg){
        height: 350px;
        margin-bottom: 40px;
    }

    &__typing-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        height: 100%;
        
        & > .typing {
            margin-top: -30px;
            color: #fff;
            font-family: $secondary-font;
            font-weight: 400;
            font-size: 4rem;
            display: flex;
            flex-direction: row;
            text-shadow: 2px 1px 1px $shadow-gray;

            @include respond(phone-lg){
                font-size: 1.8rem;
            }
        }
    }
}

.typing__cursor {
    border-right: 2px solid #fff;
    padding-left: 5px;
    animation: blink 1s infinite;
}  

@keyframes blink {
    from { opacity: 0; }
    to { opacity: 1; }
}