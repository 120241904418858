.contact {

    &__background {
        background: linear-gradient(135deg, #33cc99 0%,#000058 100%);
        clip-path: polygon(0 33%, 100% 13%, 100% 100%, 0% 100%);
        padding: 30px 0px;
        padding-top: 230px;
        margin-top: -130px;
        z-index:2;

        @include respond(phone-lg){
            clip-path: polygon(0 39%, 100% 13%, 100% 100%, 0% 100%);
            padding-top: 140px;
        }

        & > h2 {
            color: #fff;
            text-align: center;
            font-size: 2rem;
            text-shadow: 2px 1px 1px $shadow-gray;
        }
    }

    &__success {
        min-width: 300px;
        max-width: 500px;
        background: #fff;
        padding: 20px;
        padding-left: 10px;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        & > .fa-times {
            color: rgba($shadow-gray, 0.7);
            align-self: flex-end;
        }

        & > .check > .fa-check-circle {
            position: absolute;
            z-index: 3;
            color: green;
            top: 62px;
            right: 120px;
            font-size: 1.4rem;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #fff;
        }

        & > .fa-envelope {
            z-index: 2;
            font-size: 3rem;
            color: rgba($shadow-gray, 0.7);
            margin-bottom: 20px;
        }

        & > p {
            margin-bottom: 20px;
        }
    }

    &__container {
       width: 340px;
       margin: 40px auto;
       border-radius: 2px;
       background-color: #fcfcfc;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       padding: 20px 25px;

        @include respond(phone-lg){
            min-width: 350px;
            margin: 40px 20px;
        }
        
        & > a {
            text-decoration: none;
            color: $maroon;
            font-size: 1.5rem;
            font-weight: 800;

            & > svg {
                margin-right: 16px;
            }
        }
    }
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;


      @include respond(phone-lg){
        margin-right: 30px;
      }

      & > div:first-child {
        margin-bottom: 15px;
        cursor: pointer;

        & > a {
            padding-right: 0px;
            
            & > svg {
                margin-right: 7px;
            }
        }
      }

    & > div, a {
        color: #fff;
        text-decoration: none;
        font-size: 0.7rem;
        padding-right: 60px;

         @include respond(phone-lg){
             padding-right: 0px;
         }
    }
}