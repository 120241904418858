*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
    font-family: $primary-font;
    font-weight: 400;
    line-height: 1.2;
    color: $shadow-gray;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

