/*MEDIA QUERIES*/

@mixin respond ($breakpoint) {
    @if $breakpoint == phone-lg {
		@media only screen and (max-width: 25.68em) { @content };  //410px
	}
	@if $breakpoint == tab-port {
		@media only screen and (max-width: 48em) { @content };  //768px
	}
	@if $breakpoint == desktop {
		@media only screen and (min-width: 112.5em) { @content };  //1800px
	}
}
