.work {

    &__container {
        width: 100%;
        padding: 40px 0px;
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(to right bottom, rgba(45,183,137, .8), rgba(160,0,132, .8));
        clip-path: polygon(100% 0, 100% 87%, 0 100%, 0 0);
        padding-bottom: 275px;

        @include respond(phone-lg){
            clip-path: polygon(100% 0, 100% 94%, 0 95%, 0 0);
            padding-bottom: 265px;
            padding-top: 20px;
        }
    }


    &__item {
        display: flex;
        width: 90%;
        margin: 40px auto;
        flex-direction: column;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: $box-shadow;

        @include respond(desktop){
            width: 70%;

        }

        &-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;
            margin-top: 60px;
            margin-bottom: 40px;
            margin-left: 50px;

             @include respond(phone-lg){
                 flex-direction: column;
                 margin-top: 30px;
                 margin-bottom: 20px;
                 margin-left: 30px;
             }

            & > h2 {
                padding-right: 15px;
                font-size: 2.2rem;
                color: $maroon;
                font-family: $primary-font;

                 @include respond(phone-lg){
                    font-size: 2rem;
                    align-self: flex-start;
                 }
            }
            & > h3 {
                padding-bottom: 4px;
                font-weight: 300;

                @include respond(phone-lg){
                    padding-top: 10px;
                    padding-bottom: 0px;
                    align-self: flex-start;
                 }
            }
        }

    }

    &__project {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        @include respond(phone-lg){
            flex-direction: column;
        }

        @include respond(desktop){
            margin-bottom: 40px;
        }

        &.design {
            margin: 20px 55px;
            flex-direction: column;

             @include respond(phone-lg){
                 margin: 20px 25px;
             }
        }

        & > img {
            margin-top: 25px;
            margin-left: 20px;

             @include respond(desktop){
                 width: auto;
                 margin-left: 40px;
                 margin-right: 25px;
             }

             @include respond(tab-port){
                width: 600px;
            }


            @include respond(phone-lg){
                margin-top: 10px;
                margin-left: 0px;
                margin-bottom: 20px;
                align-self: center;
                width:300px;
            }
        }

        &--desc {
            margin-left: 30px;

            @include respond(phone-lg){
                width: 90%; 
            }

            &-title {
                font-weight: 700;
                margin-top: 10px;
                font-size: 1.7rem;
            }

            & > div:nth-of-type(2) {
                    font-family: $secondary-font;
                    font-weight: 300;
                    line-height: 1.5rem;
                    padding-top: 15px;
                    padding-right: 15px;
                    width: 80%;

                    @include respond(phone-lg){
                        width: 90%;
                    }
                    

                    & > p {
                        padding-bottom: 15px;
                    }

                   & > ul {
                    padding: 0px 5px;
                    padding-top: 5px;
                    margin-left: 15px;

                    & > li {
                        padding: 10px 0px;
                    }
                }
            }

             & > p:nth-of-type(1) {
                    padding-top: 25px;
                }

            & > p {
                font-family: $secondary-font;
                font-weight: 300;
                line-height: 1.5rem;
                padding-top: 15px;
                padding-right: 15px;
                width: 80%;

                @include respond(phone-lg){
                    padding-right: 0px;
                    width: 90%;
                }

                @include respond(desktop){
                    width: 98%;
                }
            }
            
        }

        &--tech {
            font-family: $secondary-font;
            font-weight: 300;
            margin-top: 20px;

            & > h4 {
                font-weight: 400;
            }

            &--icons {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-top: 20px;
                flex-wrap: wrap;

                &-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0px 20px;

                    @include respond(phone-lg){
                        padding: 10px 15px;
                    }   

                    & > svg {
                        font-size: 3rem;
                        margin-bottom: 10px;
                    }

                    & > .style-icons {
                        display: flex;
                        flex-direction: row;
                        margin-left: 8px;

                        & > svg {
                            font-size: 3rem;
                            margin-bottom: 10px;

                            &:first-child {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}