.design {

    &-img{

        &__grid {
            &-container {
                align-self: center;
                display: grid;
                grid-gap: 10px 10px;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(2, 1fr);
                margin: 40px 30px;
                margin-top: 60px;

                @include respond(phone-lg){
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(8, 1fr);
                }
            }

            &--item {
                background-color: #fcfcfc;
                justify-self: center;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                height: 300px;
                width: 300px;
                box-shadow: $box-shadow;
                z-index: 5;

                &__container {
                    position: relative;
                    cursor: pointer;
                }

                &-overlay {
                    height: 300px;
                    width: 300px;
                    background-color: rgba(45, 183, 137, 1);
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.3rem;
                    font-family: $secondary-font;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    z-index: 10;
                    opacity: 0;
                    transition: .5s ease;

                     &:hover {
                        opacity: 1;
                    }

                    & > div {
                        text-align: center;
                        padding: 0 30px;
                    }
                }
            }
        }

        &--close {
            font-size: 1rem;
            color: #888;
            align-self: flex-end;
            margin-right: 10px;
        }


        &__container {
            background-color: #e2e2e2;
            border-radius: 3px;
            box-shadow: $box-shadow;
            margin-top: -20%;
            padding: 20px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond (phone-lg){
                width: 400px;
            }


            & > img {
                height: 200px;
                width: 200px;
                border: 1px solid #ddd;
            }

        }

        &__description {
            display: flex;
            justify-content: flex-start;
            width: 70%;
            margin: 20px 0px;

            & > p {
                font-size: 1rem;
                font-family: $secondary-font;
                font-weight: 300;
                line-height: 1.5rem;
                padding: 0px 15px;
                padding-top: 15px;

                & > span {
                    line-height: 2rem;
                    font-weight: 400;
                    font-size: 1.2rem;
                }
            }

            @include respond (phone-lg){
                width: 90%;
            }
        }
    }

    &-display {

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top:20px;

              & > .design-image {
                display: none;

                &.active {
                    display: block;
                    margin:10px;
                }
            }

            @include respond (phone-lg){
                 & > img {
                    width: 350px;
                }
            }
        }

        &-controls {
            width: 65%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            margin-top: 40px;
        }

        &-left {
            color: #b0b0b0;
            &:hover {
                color: #888;
            }
        }
        &-right {
            color: #b0b0b0;
            &:hover {
                color: #888;
            }
        }
        &-dot {
            background-color: #b0b0b0;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin: 0 15px;

            &.active {
                background-color: #888;
            }
        }
    }
}

.dot__container {
    display: flex;
}