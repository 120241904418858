
.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 20px;
    padding: 0px 20px;

    &__logo {
        font-size: 4rem;
        font-weight: 700;
        font-family: $secondary-font;
        text-shadow: 2px 1px 1px $shadow-gray;
        color: $teal;
        margin-left: 20px;

         @include respond(phone-lg){
             font-size: 3rem;
             margin-left: 10px;
             align-self: flex-start;
         }
    }

    @include respond(phone-lg){
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
}

.nav-bar {
    display: flex;

     @include respond(phone-lg){
         width: 95%;
     }

    &__list {
        display: flex;
        list-style: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        @include respond(phone-lg){
            width: 35%;
        }

        &--item {
           font-size: 1.2rem;
           padding: 0px 15px;
           font-weight: 300;
           cursor: pointer;

            @include respond(phone-lg){
                font-size: 1rem;
                padding: 0px 10px;

                &:first-child, &:last-child {
                    padding: 0px;
                }
            }
           
           &__link{
                text-decoration: none;
                color: $shadow-gray;
                background-image: linear-gradient(115deg, transparent 0%, transparent 50%, $maroon 50%);
                background-size: 230%;
                transition: all .7s;
                padding: 5px 10px;
                width: 100%;

                 @include respond(phone-lg){
                     padding: 5px;
                 }

            &:hover {
                color: #fff;
                border-radius: 2px;
                background-position: 100%;
                transform: translateX(1rem) translateY(-.2rem);

                &::after {
                    transform: scaleX(1.4) scaleY(1.2);
                }
            }
           }
        }
    }
}